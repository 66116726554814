.rat-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.rat-element-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.rat-element {
  width: 100%;
  height: 95%;
  background-size: contain;
  background-color: rgb(56, 58, 61);
  background-repeat: no-repeat;
  background-position: center;
}

.rat-label {
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  text-align: center;
  height: 5%;
  line-height: 200%;
}

input {
  width: 100%;
  height: 2em;
}