.certified-input {
  border: 1px solid black;
  font-size: 1.5em;
}

.certified-button {
  cursor: pointer;
  border: 1px solid black;
  padding: .1em 1em;
  text-decoration: none;
  color: #000;
  user-select: none;
  margin: .1em;
  background: none;
}

.certified-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 512px;
  display: flex;
  flex-direction: column;
  align-items: center
}