@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');

h1, h2, button {
  font-family: 'Roboto', sans-serif;
}

@keyframes bg-scroll {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: -500px 250px;
  }

  100% {
    background-position: -1000px 500px;
  }
}

@keyframes grow {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

#home {
  width: 100vw;
  height: 100vh;
  font-size: 2em;
}

#home:before {
  background-color: #1e1e1e;
  background-image: url("./res/img/body-bg.png");
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
  z-index: -2;
  animation: bg-scroll 12s linear infinite;
}

#home:after {
  background-image: url("./res/img/bg.png");
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
  z-index: -1;
}

#home h1 {
  margin: 0;
}

#home>.container {
  height: 100%;
  gap: 0.2em;
  color: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(2px 2px 5px black);
  animation: grow 0.5s linear;
  transform-origin: center;
}

#home .button-container {
  display: flex;
  margin-top: 0.5em;
}

#home .button-container button {
  background: transparent;
  color: #ccc;
  padding: 0.4em 1em;
  border: 0.2em solid #ccc;
  border-radius: 0.2em;
  cursor: pointer;
  font-size: 0.7em;
  transition: 0.2s;
}

#home .button-container button:hover {
  background: #ccc;
  color: #1e1e1e;
  font-weight: bold;
}

@media only screen and (max-width: 800px) {
  #home {
    font-size: 1.5em;
  }

  .logo {
    width: 60vw;
    height: 60vw;
  }

  #home:before {
    background-size: 250px;
  }

  .button-container {
    margin-bottom: 100px;
  }
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}