#dema-canvas {
  border: 1px solid #ccc;
}

.dema-setting-change, .dema-parameters {
  width: 100%;
  border-radius: 0.5em;
  display: flex;
}

.dema-parameters {
  background: #141414;
  padding: 1em;
  flex-direction: column;
  gap: 1em;
}

.dema-expanded-parameters {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.2s linear;
  height: fit-content;
  max-height: 0;
}

.dema-expanded-parameters[data-active="true"] {
  transform: scaleY(1);
  max-height: 15em;
}

.dema-expand-parameters {
  width: 100%;
  cursor: pointer;
  margin-top: -1em;
  position: relative;
}

.dema-expand-parameters:before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  background: #ccc;
}

.dema-expand-parameters[data-active="true"] {
  margin-top: unset;
}

.dema-expand-parameters > div {
  background: #141414;
  width: fit-content;
  margin: auto;
  padding-right: 1.5em;
  padding-left: 0.5em;
  position: relative;
}

.dema-expand-parameters > div:after {
  content: '';
  position: absolute;
  right: 0.45em;
  top: 0.35em;
  border: 0.7em solid #0000;
  border-left-width: 0.4em;
  border-right-width: 0.4em;
  border-top-color: #ccc;
}

.dema-expand-parameters[data-active="true"] > div:after {
  transform-origin: center;
  transform: scaleY(-1) translateY(0.7em);
}

.dema-setting-change {
  flex-direction: row;
  gap: 1em;
  width: calc(100% + 2em);
}

.dema-setting-change > button {
  background: #141414;
  color: #ccc;
  border: 0;
  font-size: 1em;
  flex: 1;
  padding: 0.6em 0;
  cursor: pointer;
  position: relative;
  border-radius: 0.5em;
}

.dema-setting-change > button[data-active="true"] {
  font-weight: bold;
}

.dema-setting-change > button:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.5em;
  background: #ccc;
  left: 0;
  bottom: 0;
  transform: scaleY(0);
  border-radius: 0 0 0.5em 0.5em;
  transition: 0.1s linear;
  transform-origin: bottom;
  clip-path: polygon(0 calc(100% - 0.2em), 100% calc(100% - 0.2em), 100% 100%, 0% 100%);
}

.dema-setting-change > button[data-active="true"]:before {
  transform: scaleY(1);
}


.dema-parameters textarea {
  font-size: 1.5rem;
  height: 3.5em;
  resize: none;
  border-radius: 0.5rem;
}

.dema-input {
  border: 1px solid black;
  width: 100%;
  margin: 0;
}

.dema-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.2em;
}

.dema-checkbox > input {
  width: 1.5em;
  cursor: pointer;
}

.dema-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5em;
  gap: 0.7em;
  margin: 5vh auto;
}

.dema-slider-container {
  width: 300px;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
}

.dema-button {
  font-size: 1.5rem;
  margin: 0 0.5em;
  padding: 0.2em 1em;
  cursor: pointer;
}

.dema-crop-buttons {
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.dema-crop-buttons > button {
  flex: 1;
}

#dema-upload-modal {
  left: 0;
  top: 0;
  background: #0005;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;
  gap: 1em;
}
#dema-upload-modal[hidden] {
  display: none;
}

#cropper {
  display: block;
  max-width: 100%;
}

@media screen and (max-width: 800px) {
  .dema-container {
    font-size: 1em;
    margin-top: 1em;
  }

  .dema-parameters textarea {
    font-size: 1.5em;
  }

  .dema-button {
    font-size: 1em;
  }
}