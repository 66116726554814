html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

ol > li {
  margin-bottom: 1.5em;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
#bean {
  position: absolute;
  margin: auto;
  width: 14em;
  height: 14em;
  top: calc(50vh - 7em);
  left: calc(50vw - 7em);
  animation: spin 1s linear infinite;
  border-radius: 50%;
}

#container {
  color: #ccc;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
#container:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222;
  background-image: url("../../res/img/bg.png");
  z-index: -9999;
}

#container > .navbar {
  background: #181818;
  flex-basis: 1em;
  font-size: 3rem;
  box-shadow: 0 0 5px black;
  display: flex;
  width: 100vw;
  position: fixed;
  z-index: 9999;
}

#container > #main-section {
  padding-top: 3.6em;
  flex: 1;
}

.logo-button {
  width: 1em;
  height: 1em;
  background-image: url("../../res/img/ff14bean.png");
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  padding: 0.1em 0.3em;
  transition: 0.2s;
}

.logo-button:hover {
  background-color: #4f5d7e55;
}

.icon-menu, .icon-close {
  width: 1em;
  height: 1em;
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 0.1em 0.3em;
}

.icon-menu {
  background-image: url("../../res/img/icon-menu.svg");
}

.icon-close {
  background-image: url("../../res/img/icon-close.svg");
}


.close-region {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 500;
  background: #cccccc20;
}
.drawer {
  font-size: 1.2rem;
  position: fixed;
  background: #181818;
  display: flex;
  height: calc(100% - 3.6rem);
  top: 3.6rem;
  left: 0;
  transform: translateX(-100%);
  width: fit-content;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  z-index: 9998;
  color: #ccc
}
.drawer[data-open] {
  transform: unset;
  box-shadow: 0 0 5px black;
}
.drawer > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.drawer > ul > li > h1 {
  font-size: 1.25em;
  padding: 0.25em;
  padding-left: 0.35em;
  margin: 0.5em 0 0;
  border-bottom: 1px solid #ccc;
}
.drawer a {
  color: #ccc;
  text-decoration: none;
}
.drawer a > div {
  border: none;
  padding: 0.5em 2em 0.5em 0.5em;
  position: relative;
}
.drawer a > div:before{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
  background-color: #cccccc11;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.2s;
}
.drawer a > div:hover::before {
  transform: scaleX(1);
}

.drawer a > div.discord, .drawer a > div.fflogs {

  background-size: 1.2em;
  background-repeat: no-repeat;
  background-position: 0.5em center;
  padding-left: 2em;
}
.drawer a > div.discord {
  background-image: url("../../res/img/discord.svg");
}

.drawer a > div.fflogs {
  background-image: url("../../res/img/fflogs.png");
}

/* HOME PAGE */
.home-header, .home-header > h1 {
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 1s;
}
.home-header {
  font-size: 4rem;
  height: 500px;
  background-position: center 60%;
  filter: saturate(0.6) contrast(0.8);
  
}
.home-header > h1 {
  margin: 0;
  height: 100%;
  background-position: calc(50% + 0.09em) 60%;
  background-clip: text;
  filter:  contrast(0.6) brightness(2);
  color: transparent;
  font-size: 10vw;
  line-height: 0.8em;
  margin-left: -0.09em;
  user-select: none;
  z-index: 2;
}

.section {
  background: #181818;
  padding: 48px;
  z-index: 10;
}

.section > h1, .section > h2, .section > p {
  margin: 0;
  padding: 0;
}

.section > h1, .section > h2 {
  margin-bottom: 0.5rem;
}

.section > h2 {
  padding-top: 1em;
  margin-top: 1em;
  border-top: 1px solid #ccc;
}

ul.hyperlink-list, ul.videos, ul.diagrams, ul.toolboxes {
  list-style: none;
  padding: 0;
  margin: 0;
}

.diagrams img {
  max-width: 66%;
}

.hyperlink-list button, .toolboxes button {
  border: 2px solid #ccc;
  text-decoration: none;
  color: #ccc;
  padding: 0.2em 1em;
  transition: 0.2s;
  background-color: transparent;
  font-size: 1em;
  cursor: pointer;
}

.hyperlink-list button:hover, .toolboxes button:hover {
  color: #181818;
  background-color: #ccc;
  font-weight: bold;
}

.hyperlink-list > li + li, .videos > li + li, .diagrams > li + li, .toolboxes > li + li {
  margin-top: 0.5em;
}

.home-content {
  width: 60vw;
  min-width: 800px;
  font-size: 1.5em;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.home-content > .section:first-child {
  margin-top: -50px;
}

.home-content .section + .section {
  margin-top: 1em;
}

.section:last-child {
  margin-bottom: 50px;
}

.copyright {
  text-align: center;
  margin-bottom: 1em;
}

/* Character Card */
.character-cards {
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(2, 1fr);
}
.character-card {
  background: #222;
  padding: 1em;
  display: flex;
  flex-direction: row;
  position:relative;
  gap: 1em;
}
.character-card > .character-image {
  width: 5em;
  height: 5em;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.character-card > .character-image[data-crown]:after {
  content: '👑';
  position: absolute;
  font-size: 2rem;
  width: 1em;
  height: 1em;
  bottom: 100%;
  left: calc(100% - 0.6em);
  transform: rotate(30deg);
}
.character-card > .character-info {
  flex: 1;
  height: 5em;
}
.character-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5em;
  gap: 0.5em;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.2em;
  padding-bottom: 0.2em;
}
.character-header h1 {
  font-size: 0.7em;
  margin: 0;
}
.character-info {
  display: flex;
  flex-direction: column;
}
.character-description {
  overflow-y: auto;
}
.character-card p {
  margin: 0;
}
.character-job {
  width: 1em;
  height: 1em;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Abyssos */

.encounter-header {
  width: 100%;
  height: 360px;
  background-position: center 30%;
  background-size: cover;
  margin-bottom: -50px;
  position: relative;
}

.encounter-navigation {
  position: absolute;
  width: fit-content;
  top: calc(50% - 0.5em);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  gap: 1em;
  padding: 1em;
  backdrop-filter: blur(10px) saturate(0.5);
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 100%;
  z-index: 2;
}


.page-abyssos, .page-anabaseios, .page-dragonsong, .page-omega {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 60vw;
  margin: 0 auto;
  gap: 1em;
  min-width: 800px;
}

.encounter-home, .abyssos-guide, .anabaseios-guide, .dragonsong-guide, .omega-guide {
  font-size: 1.5rem;
  flex: 1;
}

.table-of-contents {
  font-size: 1rem;
  position: sticky;
  top: 5em;
}
.table-of-contents > h1 {
  border-bottom: 1px solid #ccc;
}
.table-of-contents > ul {
  margin: 0; padding: 0 0 0 1em;
}
.table-of-contents > ul > li {
  margin-top: 0.5em;
  cursor: pointer;
}
.table-of-contents > ul > li:hover {
  text-decoration: underline;
}
.table-of-contents > ul > li[data-current="true"] {
  font-weight: bold;
}
.table-of-contents > .top {
  cursor: pointer;
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 175%;
  bottom: 1em;
  right: 1em;
  border-radius: 2px;
  transition: 0.2s;
}
.table-of-contents > .top:hover {
  background: #ccc;
  color: #181818;
  font-weight: bold;
}
.mobile-toc-toggle+.close-region {
  z-index: 400;
}
.table-of-contents.toc-mobile {
  position: fixed;
  width: calc(100vw - 5em);
  z-index: 401;
  left: 0.5em;
  top: 4em;
}

.mobile-toc-toggle {
  position: fixed;
  width: 2.5em;
  height: 2.5em;
  top: 10%;
  right: -2px;
  background: #cccccc55;
  z-index: 999;
  border-radius: 2em 0 0 2em;
  filter: drop-shadow(-2px 2px 2px #00000055);
  background-image: url("../../res/img/manage_search.svg");
  background-size: 2em;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(5px);
  border: 1px solid white;
}

.boss-button {
  width: 3em;
  height: 3em;
  background: white;
  background-size: contain;
  filter: saturate(0.2);
  transition-duration: 0.4s;
  transition-property: opacity, filter;
  position: relative;
}
.boss-button:after {
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  content: attr(data-label);
  color: #ccc;
  filter: drop-shadow(0 0 8px black) drop-shadow(0 0 5px black) drop-shadow(0 0 2px black);
  opacity: 0;
  transition-duration: 0.4s;
  transition-property: opacity, filter;
}
.boss-button:hover:after, .boss-button[data-active]:after {
  opacity: 1;
}
.boss-button[data-active] {
  filter: unset;
}

.timeline-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
}
.timeline-container > .timeline {
  cursor: default;
  flex: 1 1 0;
}
.timeline-container > .timeline .timeline-mechanic {
  font-weight: bold;
}
.timeline-container > .mechanic-description {
  position: sticky;
  top: 5em;
  padding: 1em;
  background: #cccccc11;
  height: fit-content;
  flex: 1 1 0;
}
.timeline-container > .mechanic-description > h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  margin: 0;
  line-height: 80%;
}
.timeline-container > .mechanic-description > h2 > span.mechanic-type {
  font-size: 1rem;
  border-left: 1px solid #ccc;
  padding-left: 0.5em;
  margin-left: 0.5em;
}
.timeline-container > .mechanic-description > p {
  margin: 0;
  margin-top: 0.5rem;
  white-space: pre-line;
}
.timeline-container > .mechanic-description > p + p {
  margin-top: 1rem;
}
@media only screen and (max-width: 1920px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 800px) {
  html {
    font-size: unset;
  }
  #container > .navbar {
    font-size: 2.5rem;
  }

  #container > #main-section {
    padding-top: 3em;
  }

  .drawer {
    top: 3rem;
    height: calc(100% - 3rem);
  }

  .drawer a > div {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .home-header, .encounter-header {
    height: 256px;
  }
  .home-header > h1{
    font-size: 12vw;
  }

  .section {
    padding: 12px;
  }

  .home-content {
    font-size: 1em;
    width: calc(100vw - 24px);
    min-width: unset;
  }
  .character-cards {
    grid-template-columns: repeat(1, 1fr);
  }

  /* ABYSSOS */
  .encounter-header {
    height: 150px;
  }
  .encounter-navigation {
    font-size: 4.5vw;
    width: calc(100vw - 2em);
  }
  .encounter-navigation.dragonsong, .encounter-navigation.omega {
    font-size: 3.2vw;
  }
  .page-abyssos, .page-anabaseios, .page-dragonsong, .page-omega {
    font-size: 1rem;
    width: calc(100vw - 24px);
    margin: 1.5em auto;
    min-width: unset;
  }
  .encounter-home, .abyssos-guide, .anabaseios-guide, .dragonsong-guide, .omega-guide {
    font-size: 1rem;
  }
  .abyssos-guide ul, .anabaseios-guide ul, .dragonsong-guide ul, .omega-guide ul {
    padding-left: 1em;
  }
  .timeline-container {
    gap: 4px;
    font-size: 0.8rem;
  }
  .timeline-container > .mechanic-description > h2 {
    line-height: 100%;
  }
  .timeline-container > .mechanic-description > h2 > span.mechanic-type {
    font-size: 0.5em;
  }
  img, iframe {
    max-width: 80%;
    height: auto;
  }
}


/* DIALOG BOX */
.dialog.inner {
  background: linear-gradient(180deg, rgba(119,119,119,1) 0, rgba(96,98,96,1) 1px, rgba(49,48,49,1) 20px);
  width: fit-content;
  padding: 1em 1em;
  border-radius: 0.7em;
  position: relative;
}



.dialog.outer {
  border: 2px solid #947D5A;
  background: #7F693D;
  width: fit-content;
  padding: 0.5px;
  border-radius: 0.9em;
  position: relative;
}

.dialog.outer::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 1em #D6D7B9, 0 -1px 1px #D6D7B9;
  top: 0;
  left: 0;
  border-radius: 0.7em;
}

.dialog.outer::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0.7em;
  box-shadow: inset 0 0 2px 1px black;
  pointer-events: none;
}

.dialog button {
  height: 1.6em;
  border-radius: 1em;
  width: 100%;
  cursor: pointer;
  background: linear-gradient(180deg, rgba(89,89,89,1) 0%, rgba(72,71,71,1) 50%, rgba(59,58,58,1) 50%, rgba(73,73,73,1) 100%);
  border: none;
  filter: drop-shadow(0px 1px 1px black);
  border-top: 1px solid #767676;
  color: #eee;
}

.dialog button:hover {
  background: linear-gradient(180deg, rgba(107,107,107,1) 0%, rgba(88,87,87,1) 50%, rgba(71,71,71,1) 50%, rgba(89,89,89,1) 100%);
}

.dialog button:active {
  transform: translateY(1px);
}

#gear-page {
  margin: 2em auto;
}

.gear-users {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
  width: 80vw;
  font-size: 1.5em;
}
.gear-user {

}

.gear-user > header {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.dialog hr {
  border: 0;
  border-top: 1.5px solid black;
  border-bottom: 1.5px solid #565556;
  margin: 0.2em 0;
}